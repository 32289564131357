<template>
  <div>
    <b-container fluid class="mtop-200 contact">
      <h1 class="text-center">{{ $t('contact_us') }}</h1>
       <b-row class="contact-content">
        <b-col>
          <b-form dir="rtl">
          <b-form-group id="name" label-for="name">
            <b-form-input id="name_input" :placeholder="$t('name')" size="lg" v-model="name"></b-form-input>
          </b-form-group>
          <b-form-group id="email" label-for="email">
            <b-form-input id="email_input" :placeholder="$t('email')" size="lg" v-model="email"></b-form-input>
          </b-form-group>
          <b-form-group id="phone" label-for="phone">
            <b-form-input id="phone_input" :placeholder="$t('mobile')" size="lg" v-model="phone"></b-form-input>
          </b-form-group>
          <b-form-group id="message" label-for="message">
            <b-form-textarea id="message_input" v-model="message" :placeholder="$t('message')" rows="3" max-rows="6"></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <b-button :disabled="btnDisabled" @click="sendMessage" class="btn-rounded black arabic float-right" size="lg" variant="outline-primary">{{ $t('send') }}</b-button>
          </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
 </div>
</template>

<script>
export default {
  name: 'Contact',
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      message: ""
    }
  },
  components: {

  },
  computed: {
    btnDisabled() {
      return this.name === "" || this.email === "" ||this.phone === "" || this.message === ""
    }
  },
  methods: {
    emptyForm() {
      this.name = this.email = this.phone = this.message = ""
    },
    sendMessage() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message
      }
      this.$store.dispatch("auth/sendMessage", payload)
      .then(() => {
        this.emptyForm()
        loader.hide()
        this.$toast.success("تم ارسال الرسالة بنجاح")
      })
      .catch((err) => {
        loader.hide()
        if (err.data.error.error) {
          err.data.error.message.forEach(message => {
            this.$toast.warning(message)
          })
        }
      })
    }
  },
}
</script>
