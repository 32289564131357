<template>
  <div>
    <contact />
  </div>
</template>


<script>
import Contact from "@/components/Contact.vue"

export default {
  components: {
    Contact
  }

}
</script>

<style lang="scss">
</style>
